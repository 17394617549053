import { firebaseApp } from "Components/Firebase";
import {
  CollectionReference,
  DocumentReference,
  QueryDocumentSnapshot,
  collection,
  doc,
  getFirestore,
  query,
} from "firebase/firestore";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import {
  ConfigDocument,
  NomineeClassifications,
  WinnerDocument,
  YearWinnersDocument,
} from "types/types";
import { Header } from "Components/Header";
import { AthleteList, Main } from "../Home";
import { SearchBar } from "Components/SearchBar";
import { H1, H2, Subtitle1 } from "Components/Text";
import styled, { css } from "styled-components";
import { ClassChip, MonthChip } from "Components/DateChip";
import defaultImg from "Assets/Images/default.jpg";
import { ReactComponent as ChevronSvg } from "Assets/Svgs/ChevronDown.svg";
import { useEffect, useState } from "react";
import { AthleteCard, OpenModal, Wrapper } from "Components/AthleteCard";
import { Footer } from "Components/Footer";
import { Modal } from "Components/Modal";
import { Dropdown } from "Components/RegistrationForm/Fields/Field/SelectField";
import { DropdownItem } from "Routes/Admin";
import { DateTime } from "luxon";

export type AthleteData = WinnerDocument & {
  featuredMonth?: string;
  featuredYear?: string;
};

export const Alumni = () => {
  const [athleteClass, setAthleteClass] = useState<string>("6A");
  const [displayMonth, setDisplayMonth] = useState<string>();
  const [displayYear, setDisplayYear] = useState<number>();
  const [openList, setOpenList] = useState(false);
  const [yearList, setYearList] = useState<number[]>([]);
  const [monthList, setMonthList] = useState<string[]>([]);
  const [noResult, setNoResult] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<AthleteData[] | null>();
  const [athleteData, setAthleteData] = useState<AthleteData[]>();
  const [completeSeason, setCompleteSeason] = useState<string>();

  const db = getFirestore(firebaseApp);
  const [winnerDocs] = useCollection(
    query(collection(db, "winners") as CollectionReference<WinnerDocument>),
  );
  const [winnerByYearDocs] = useCollection(
    query(
      collection(db, "yearWinners") as CollectionReference<YearWinnersDocument>,
    ),
  );
  const [siteData] = useDocument(
    doc(db, "config/site") as DocumentReference<ConfigDocument>,
  );

  useEffect(() => {
    const month = siteData?.data()?.currentMonth;
    const season = siteData?.data()?.completeSeason;
    const activeSeasons = siteData?.data()?.activeSeasons || [];
    if (month) {
      if (DateTime.fromJSDate(month?.toDate()).toFormat("LLLL") === "June") {
        setDisplayMonth(
          DateTime.fromJSDate(month?.toDate())
            .set({ month: 5 })
            .toFormat("LLL yyyy"),
        );
      } else {
        setDisplayMonth(
          DateTime.fromJSDate(month?.toDate()).toFormat("LLL yyyy"),
        );
      }
    }
    if (season) {
      setCompleteSeason(DateTime.fromJSDate(season.toDate()).toFormat("yyyy"));
    }
    if (activeSeasons) {
      setYearList([...activeSeasons].reverse());
      setDisplayYear(activeSeasons[activeSeasons.length - 1]);
    }
  }, [siteData]);

  useEffect(() => {
    if (winnerDocs) {
      let docData = winnerDocs?.docs?.map((doc) => {
        let date = doc.get("featuredDate");
        return {
          id: doc.id,
          ...doc?.data(),
          featuredMonth: DateTime.fromJSDate(date.toDate()).toFormat("LLL"),
          featuredYear: DateTime.fromJSDate(date.toDate()).toFormat("yyyy"),
        };
      });
      setAthleteData(docData);
    }
  }, [winnerDocs]);

  useEffect(() => {
    let athleteDataCopy = [...(athleteData || [])];
    let monthList = athleteDataCopy
      ?.sort((a: AthleteData, b: AthleteData) =>
        DateTime.fromJSDate(a?.featuredDate.toDate()).toMillis() >
        DateTime.fromJSDate(b?.featuredDate.toDate()).toMillis()
          ? 1
          : -1,
      )
      ?.reduce((acc: string[], data: AthleteData) => {
        let startList = DateTime.fromJSDate(
          siteData?.data()?.currentMonth?.toDate() || new Date(),
        );
        let month = DateTime.fromJSDate(data.featuredDate.toDate()).toFormat(
          "LLL yyyy",
        );
        if (
          !acc.includes(month) &&
          startList &&
          DateTime.fromJSDate(data.featuredDate.toDate())
            .set({ day: 15, hour: 0, minute: 0, second: 0 })
            .toMillis() <
            DateTime.fromFormat(((displayYear || 0) + 1).toString(), "yyyy")
              .set({ month: 9, day: 15 })
              .toMillis() &&
          DateTime.fromJSDate(data.featuredDate.toDate())
            .set({ day: 15, hour: 0, minute: 0, second: 0 })
            .toMillis() >=
            DateTime.fromFormat((displayYear || 0).toString(), "yyyy")
              .set({ month: 9, day: 15 })
              .toMillis() &&
          DateTime.fromJSDate(data.featuredDate.toDate()).startOf("month") <=
            startList?.startOf("month")
        ) {
          acc.push(month);
        }
        return acc;
      }, []);
    setMonthList(monthList);
  }, [athleteData, siteData, displayYear]);

  const renderYearWinner = () => {
    const yearlyWinners = winnerByYearDocs?.docs;
    const [filteredWinner] = (yearlyWinners || []).filter(
      (doc: QueryDocumentSnapshot<YearWinnersDocument>) => {
        const [featuredYear, featuredClass] = doc?.id.split("-");
        return (
          featuredYear === displayYear?.toString().slice(2, 4) &&
          (featuredClass === "PR" ? "Private" : featuredClass) === athleteClass
        );
      },
    );
    let winner = filteredWinner?.data();

    return (
      <CardWrapper>
        <Image>
          <img src={winner?.athletePhoto || defaultImg} alt="" />
        </Image>
        <Details>
          <Name>
            <H2>{winner?.athleteName}</H2>
          </Name>
          <Info>
            <Subtitle1>{winner?.athleteGrade}</Subtitle1>
            <Subtitle1>{winner?.athleteSport}</Subtitle1>
            <Subtitle1>{winner?.athleteSchool}</Subtitle1>
          </Info>
        </Details>
      </CardWrapper>
    );
  };

  const search = (searchValue: string) => {
    setNoResult(false);
    if (!searchValue) {
      setSearchData(null);
    }
    if (searchValue) {
      let result = athleteData?.filter((athlete: AthleteData) =>
        athlete?.athleteName?.toLowerCase().includes(searchValue.toLowerCase()),
      );
      if (!result?.length) {
        setNoResult(true);
      } else setSearchData(result as unknown as AthleteData[]);
    }
  };

  return (
    <Main>
      <Header />
      <SearchContainer>
        <H1
          onClick={() => {
            yearList.length > 1 && setOpenList(!openList);
          }}
        >
          {displayYear && `${displayYear}-${displayYear + 1}`} Alumni{" "}
          {yearList.length > 1 && <ChevronSvg />}
          <Dropdown open={openList}>
            {yearList.map((option) => {
              return (
                <DropdownItem
                  key={option}
                  selected={option === displayYear}
                  onClick={() => {
                    setDisplayYear(option);
                    setDisplayMonth(`Sep ${option}`);
                    setOpenList(false);
                  }}
                >
                  <>
                    Season {option}-{option + 1}
                  </>
                </DropdownItem>
              );
            })}
          </Dropdown>
        </H1>
        <SearchBar search={search} />
      </SearchContainer>

      {completeSeason &&
        completeSeason === displayYear?.toString() &&
        !searchData && (
          <YearContainer>
            <H2>Player of the Year</H2>
            <ChipContainer>
              {NomineeClassifications.map((classification: string) => (
                <ClassChip
                  key={classification}
                  athleteClass={
                    classification === "Private" ? "PR" : classification
                  }
                  active={athleteClass === classification}
                  onClick={() => setAthleteClass(classification)}
                />
              ))}
            </ChipContainer>
            {renderYearWinner()}
          </YearContainer>
        )}

      <MonthContainer hasSearch={!!searchData}>
        <H2>
          {searchData || noResult ? "Search Results" : "Athlete of the Month"}
        </H2>
        {!searchData && !noResult && (
          <MonthChipContainer>
            {monthList?.map((month: string) => (
              <MonthChip
                month={month.split(" ")[0].toUpperCase()}
                active={displayMonth === month}
                onClick={() => setDisplayMonth(month)}
              />
            ))}
          </MonthChipContainer>
        )}
        <AthleteList>
          {noResult ? (
            <div>No results found</div>
          ) : (
            (searchData || athleteData)
              ?.sort(
                (a: AthleteData, b: AthleteData) =>
                  parseInt(b.athleteClassification || "0") -
                  parseInt(a.athleteClassification || "0"),
              )
              ?.filter((data: AthleteData) => {
                return !searchData?.length
                  ? `${data.featuredMonth} ${data.featuredYear}` ===
                      displayMonth
                  : true;
              })
              ?.map((data: AthleteData) => {
                return (
                  <AthleteCard
                    key={data.athletePhoto}
                    src={data.athletePhoto || defaultImg}
                    name={data?.athleteName || ""}
                    grade={data?.athleteGrade || ""}
                    sport={data?.athleteSport || ""}
                    school={data?.athleteSchool || ""}
                    category={data?.athleteClassification || ""}
                    details={data?.athleteProfile || ""}
                  />
                );
              })
          )}
        </AthleteList>
      </MonthContainer>
      <Footer />
    </Main>
  );
};
const YearContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  background-color: #000259;
  color: #fff;
  padding: 50px 100px;
  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 50px 10px;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30px;
  }
`;
const MonthContainer = styled.div<{ hasSearch: boolean }>`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  color: #000259;
  max-width: 1220px;
  background-color: #fff;
  padding: 50px 0;
  ${({ hasSearch }) =>
    hasSearch &&
    css`
      align-items: flex-start;
      justify-content: flex-start;
      gap: 50px;
    `}
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30px 20px;
  }
`;
const SearchContainer = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1220px;
  color: #112b4d;
  padding: 50px 0;
  border-bottom: 1px solid #ccc;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    padding: 35px 20px;
  }
  ${H1} {
    position: relative;
    cursor: pointer;
    ${Dropdown} {
      width: 300px;
    }
  }
`;
const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 30px 0 50px;
  gap: 35px;
  flex-wrap: wrap;
`;
const MonthChipContainer = styled.div<{ isTablet?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 30px 0 50px;
  gap: 25px;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
    flex-wrap: wrap;
  }
`;
const CardWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  max-width: 900px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    width: 100%;
  }
`;
const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${Subtitle1} {
    font-size: 17px;
  }
`;
const Name = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;
const Details = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Image = styled.div`
  width: 50%;
  display: flex;
  img {
    object-fit: cover;
    width: 100%;
    /* height: 500px;
    width: 300px; */
    object-position: top center;
  }
`;
